import React, {useContext} from "react";
import {Button, Grid, Stack, Typography} from "@mui/material";
import DarkCity from "./images/DarkCity.png";
import {CONSULT_REF, RECRUITER_REF, ScrollToContext} from "./components/ScrollToContext";


export const IntroSection = () => {
    const [scrollToRef, scrollTo] = useContext(ScrollToContext)
    return (
        <Grid container sx={{backgroundImage: `url(${DarkCity})`, height: "400px", alignItems: "center"}}>
            <Grid item xs={12} sm={12}>
                <Typography component="div"
                            variant="h4"
                            color="White"
                            sx={{textAlign: 'center'}}>
                    TECH REKRYTERING
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Button variant="contained" onClick={()=> scrollTo(CONSULT_REF)}>För&nbsp;konsulter</Button>
            </Grid>
            <Grid item xs={12} sm={6} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Button variant="contained" onClick={()=> scrollTo(RECRUITER_REF)}>För&nbsp;rekryterare</Button>
            </Grid>
        </Grid>
    );
}