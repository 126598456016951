import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            // main: '#00ffff',
            main: '#D2E2F4',
        },
        secondary: {
            main: '#19857b',
        },
        panel: {
            main: '#243E5B',
        },
        footer: {
            main: '#6E617B',
        },
        heading: {
            main: '#F96544',
        },
        error: {
            main: red.A400,
        },
    },
});

export default theme;