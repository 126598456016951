import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Main from "./Main";
import {Box, Container} from "@mui/material";
import {WithScrollTo} from "./components/ScrollToContext";

function App() {

    return (
        <WithScrollTo>
            <Container maxWidth="lg" style={{scrollBehavior: "smooth", minWidth: "400px"}}>
                <Header/>
                <Box sx={{my: 4}}>
                    <Main/>
                </Box>
                <Footer/>
            </Container>
        </WithScrollTo>
    );
}

export default App;
