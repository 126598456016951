import React, {useContext, useEffect, useRef} from 'react';
import {Box, Card, CardMedia, Grid, Typography, useTheme} from "@mui/material";
import {ScrollToContext} from "./ScrollToContext";

export default function SectionLayout(props) {
    const theme = useTheme();
    const ref = useRef();
    const [scrollToRef, scrollTo] = useContext(ScrollToContext)

    useEffect(() => {
        const element = ref.current;
    }, []);

    useEffect( () => {
        if (props.scrollTo && scrollToRef === props.scrollTo) {
            if (ref.current)
                ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
            else
                console.warn('ref.current is', ref.current);
        }

    }, [scrollToRef])

    switch (props.layout) {
        case "IMAGE_LEFT": {
            return (
                <Grid container spacing={4} ref={ref} justifyContent="space-around">
                    <Grid item xs={12} md={12}>
                        <Typography component="div"
                                    variant="h4"
                                    color={theme.palette.heading.main}
                                    sx={{textAlign: 'center'}}>
                            {props.header}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CardMedia
                            component="img"
                            image={props.image}
                            alt="Person"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" color="text.secondary" component="div">
                            {props.ingress}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            {props.body}
                        </Typography>
                    </Grid>
                </Grid>
            )
        }
        case "IMAGE_RIGHT": {
            return (
                <Grid container spacing={4}  ref={ref} justifyContent="space-around">
                    <Grid item xs={12} md={12}>
                        <Typography component="div"
                                    variant="h4"
                                    color={theme.palette.heading.main}
                                    sx={{textAlign: 'center'}}>
                            {props.header}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography variant="h6" color="text.secondary" component="div">
                                {props.ingress}
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                {props.body}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CardMedia
                            component="img"
                            image={props.image}
                            alt="Person"
                        />
                    </Grid>
                </Grid>
            )
        }
        default: {
            return (
                <Card>
                    <Typography component="div"
                                variant="h4"
                                color={theme.palette.heading.main}
                                sx={{textAlign: 'center'}}>
                        {props.header}
                    </Typography>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="h6" color="text.secondary" component="div">
                            {props.ingress}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            {props.body}
                        </Typography>
                    </Box>
                </Card>
            )
        }

    }
}