import React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Logo from "./images/TechRec2.png";
import {Button, Grid, Icon, IconButton} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

export default function Footer() {
    return (
        <AppBar position="static" color="primary" sx={{top: 'auto', bottom: 0}}>
            <Grid container direction="row" justifyContent="space-around">
                <Grid item sx={12} md={4} alignContent="center" justifyContent="center">
                    <img
                        src={Logo}
                        height="200"
                        width="200"
                        style={{backgroundColor: "transparent"}}
                        alt="Techrekrytering logo"/>
                </Grid>
                <Grid item sx={12} md={6}>
                    <Grid container spacing={1} direction="column" justifyContent="space-between">
                        <Grid item sx={12} md={8}>
                            <Typography variant="h5" gutterBottom component="div" sx={{p: 2, pb: 0}}>
                                KONTAKTUPPGIFTER
                            </Typography>
                        </Grid>
                        <Grid item sx={12} md={8}>
                            <Button
                                variant="contained"
                                onClick={() => {navigator.clipboard.writeText("info@techrekrytering.se")}}
                                startIcon={<EmailIcon/>}>
                                info@techrekrytering.se
                            </Button>
                        </Grid>
                        <Grid item sx={12} md={8}>
                            <Button variant="contained" startIcon={<ContactPhoneIcon/>}>0760314428</Button>
                        </Grid>
{/*                        <Grid item sx={12} md={8}>
                            <Button variant="contained" startIcon={<LinkedInIcon/>}>linkedin</Button>
                        </Grid>*/}
                    </Grid>
                </Grid>
            </Grid>
        </AppBar>
    )
}