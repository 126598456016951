import React, {useContext} from 'react';
import {AppBar, Button, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Logo from './images/TechRec2.png';
import {ABOUT_REF, CONSULT_REF, RECRUITER_REF, ScrollToContext} from "./components/ScrollToContext";

export default function Header(props) {
    const [scrollToRef, scrollTo] = useContext(ScrollToContext)

    return (
        <AppBar position="static">
            <Toolbar>
                <a href="/">
                    <img
                        src={Logo}
                        height="50"
                        width="50"
                        style={{backgroundColor: "transparent"}}
                        alt="Techrekrytering logo"/>
                </a>
                <Button color="inherit" onClick={()=> scrollTo(CONSULT_REF)}>
                    För Konsulter
                </Button>
                <Button color="inherit" onClick={()=> scrollTo(RECRUITER_REF)}>
                    För Rekryterare
                </Button>
                <Button color="inherit" onClick={()=> scrollTo(ABOUT_REF)}>
                    Om
                </Button>
            </Toolbar>
        </AppBar>
    )
}