import React from 'react';
import SectionLayout from "./components/SectionLayout";
import Person from "./images/person.jpeg";
import Happy from "./images/happy.jpeg";
import Logo from './images/TechRec2.png';
import {IntroSection} from "./IntroSection";
import {ABOUT_REF, CONSULT_REF, RECRUITER_REF} from "./components/ScrollToContext";
import {Paper} from "@mui/material";

export default function Main(props) {
    return <>
        <IntroSection/>
        <SectionLayout
            layout="IMAGE_LEFT"
            scrollTo={CONSULT_REF}
            header="Varför skall ni ta uppdrag via oss?"
            ingress="Vårt uppdrag är att göra teknisk rekrytering enklare och mer effektivt för både arbetsgivare och arbetssökande."
            body="Vi tror på att bygga långsiktiga relationer med våra kunder och kandidater genom att erbjuda enastående kundservice, tydlig kommunikation och snabb respons. Vi är stolta över att erbjuda en transparent och rättvis rekryteringsprocess som är utformad för att möta de unika behoven hos varje kund och kandidat. Vi arbetar hårt för att säkerställa att både kunden och kandidaten är nöjda med resultatet."
            image={Person}
            ref={props.scrollToConsultSectionRef}/>
        <hr/>
        <SectionLayout
            layout="IMAGE_RIGHT"
            scrollTo={RECRUITER_REF}
            header="Varför rekrytera genom oss?"
            ingress="Techrekrytering är en specialistrekryteringsbyrå som är inriktad på att hitta de bästa tekniska kandidaterna."
            body="Vi arbetar med företag från start-ups till stora företag och hjälper dem att hitta de bästa kandidaterna  inom områden som webbutveckling, programmering, mjukvaruutveckling, dataanalys, maskininlärning, AI, molntjänster och mer."
            image={Happy}
            ref={props.scrollToRecruiterSectionRef}/>
        <hr/>
        <SectionLayout
            layout="IMAGE_LEFT"
            scrollTo={ABOUT_REF}
            header="Om Techrekrytering"
            ingress="Välkommen till Tech Rekrytering - ett nytänkande konsult- och mäklarföretag med erfarenhet inom försäljning och ledning."
            body="Vårt mål är att hjälpa företag att hitta kandidater och vi värderar rätt person på rätt plats som ett av våra nyckelord. Vi växer dagligen så följ med på våran resa och kontakta oss redan idag, ta reda på mer om hur vi kan hjälpa dig."
            image={Logo}
            ref={props.scrollToAboutSectionRef}/>
    </>
}
