import React, {useState} from 'react';

const ScrollToContext = React.createContext('INITIAL');

const CONSULT_REF = "CONSULT_REF";
const RECRUITER_REF = "RECRUITER_REF";
const ABOUT_REF = "ABOUT_REF";

const WithScrollTo = (props) => {
    const [scrollToRef, scrollTo] = useState('INITIAL');

    return (
        <ScrollToContext.Provider value={[scrollToRef, scrollTo]}>
            {props.children}
        </ScrollToContext.Provider>
    )
};

export {WithScrollTo, ScrollToContext, ABOUT_REF, RECRUITER_REF, CONSULT_REF}